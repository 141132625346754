// import modules
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Footer from "../../library/common/components/Footer";
// import Modal from "./Modal";
import NavBar from "../../library/common/components/NavBar";
import pfp from "../../resources/img/pfp.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <React.Fragment>
      {/*this.state.isModalVisible && <Modal toggle={this.handleModal} />*/}
      <NavBar background="#283149" />
      <main id="about">
        <article className="wrapper">
          <h1>This is who I am</h1>
          <p>
            Are you looking for a data scientist/machine learning engineer? Look no further! I am a data scientist, a specialist
            in NLP, Large Language Models and Prompt Engineering.
          </p>
          <section>
            <img src={pfp} alt="A photo of me" />
            <div>
              <h2>What I do</h2>
              <p>
                With over 4 years of experience in the IT industry, 
                I build applications leveraging generative AI, particularly LLMs such as GPT-4 and Llama2, 
                as well as technologies and platforms such as Python, LangChain, SQL, AWS and Weights and Biases.
                Having performed multiple roles such as full-stack development, test automation and data science, I offer
                an integral consultancy on how to build your application with generative AI, so that you don't get left
                behind in this AI revolution!
              </p>
            </div>
          </section>
          <section>
            <div>
              <h2>Why I do what I do</h2>
              <p>
                The rise of AI powered applications is here. If you're a developer or running a business, 
                not utilizing AI in some way means you're overlooking a soon-to-be essential advantage that will 
                be necessary to stay competitive. This is why I have spent the last 2 years studying everything related 
                to AI, Machine Learning and data science in general.    
              </p>
            </div>
          </section>
          <section>
            <div>
              <h2>About my experience in AI</h2>
              <p>
              After going through a highly selective 6-month Machine Learning fellowship program called AnyoneAI, 
              I currently work in a contract NLP Data Scientist Position at SimSkills, where I am in charge of 
              building LLM powered simulations using prompt engineering and fine tuning. I invite you to know more about my journey,
              you can find my LinkedIn below, and check my <Link to="/blog/data-projects">blog/portfolio</Link>.
              </p>
            </div>
          </section>
        </article>
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default About;
