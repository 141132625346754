import React from "react";
import ContentLoader from "react-content-loader";

const PostContentLoader = (props: any) => {
  return (
    <div
      style={{
        maxWidth: "48rem",
        margin: "0 auto 1rem",
        boxShadow: "0px 0px 0.6rem #404b69",
      }}
    >
      <ContentLoader
        viewBox="0 0 100% 18vh"
        height={"18vh"}
        width={"100%"}
        {...props}
        backgroundColor="#D4DCF9"
        foregroundColor="#BADBDB"
        style={{ background: "#283149", marginBottom: "-0.3rem" }}
      >
        <rect x="15%" y="1rem" rx="5" ry="5" width="70%" height="32%" />
        <rect x="35%" y="70%" rx="5" ry="5" width="30%" height="15%" />
      </ContentLoader>
      <ContentLoader
        viewBox="0 0 100% 68vh"
        height={"68vh"}
        width={"100%"}
        {...props}
        backgroundColor="#D4DCF9"
        foregroundColor="#BADBDB"
      >
        <rect x="0" y="0" width="100%" height="75%" />
        <rect x="0" y="79%" rx="5" ry="5" width="100%" height="3%" />
        <rect x="0" y="83%" rx="5" ry="5" width="100%" height="3%" />
        <rect x="0" y="87%" rx="5" ry="5" width="100%" height="3%" />
        <rect x="0" y="91%" rx="5" ry="5" width="100%" height="3%" />
        <rect x="0" y="95%" rx="5" ry="5" width="100%" height="3%" />
      </ContentLoader>
    </div>
  );
};

export default PostContentLoader;
