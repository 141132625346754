import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FooterContact() {
  return (
    <div className="footer-contact">
      <a
        href="https://www.linkedin.com/in/ciro-villafraz/"
        target="_blank"
        item-prop="url"
        aria-label="linkedin"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "linkedin"]} /> https://www.linkedin.com/in/ciro-villafraz/
      </a>
      <a href="mailto:contact@cirov.com" /*onClick={this.handleModal}*/>
        <FontAwesomeIcon icon={["far", "envelope"]} /> contact@cirov.com
      </a>
      <a
        href="https://github.com/cvillafraz"
        target="_blank"
        item-prop="url"
        aria-label="github"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "github"]} /> https://github.com/cvillafraz
      </a>

    </div>
  );
}
