import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Error from "../../library/common/components/Error";
import Home from "../../modules/Home";
//import Portfolio from "../../modules/Portfolio";
import Blog from "../../modules/Blog";
import Post from "../../modules/Post";
import About from "../../modules/About";

function Routes(): React.ReactElement<{}> {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/blog/:category?" component={Blog} />
        <Route exact path="/post/:post_slug" component={Post} />
        <Route component={Error} />
      </Switch>
    </Router>
  );
}
export default Routes;
