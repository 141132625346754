import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHackerrank } from "@fortawesome/free-brands-svg-icons";

export default function SocialIcons() {
  return (
    <div className="social-icons">
      <a
        href="https://www.linkedin.com/in/ciro-villafraz/"
        target="_blank"
        item-prop="url"
        aria-label="linkedin"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "linkedin"]} />
      </a>
      <a
        href="https://twitter.com/cvillafraz"
        target="_blank"
        item-prop="url"
        aria-label="twitter"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "twitter"]} />
      </a>
      <a href="mailto:contact@cirov.com" /*onClick={this.handleModal}*/>
        <FontAwesomeIcon icon={["far", "envelope"]} />
      </a>
      <a
        href="https://github.com/cvillafraz"
        target="_blank"
        item-prop="url"
        aria-label="github"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={["fab", "github"]} />
      </a>
      <a
        href="https://www.hackerrank.com/ciro1200"
        target="_blank"
        item-prop="url"
        aria-label="twitter"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faHackerrank} />
      </a>
    </div>
  );
}
