// import modules
import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchPosts } from "../../library/common/actions/postAction";
import Error from "../../library/common/components/Error";
import Footer from "../../library/common/components/Footer";
import NavBar from "../../library/common/components/NavBar";
import PostItemLoader from "../../library/common/components/PostItemLoader";
const Parser = require("html-react-parser");
// Define props interface
interface Iprops {
  posts: any[];
  fetchPosts: any;
  error?: Error;
}
// Blog component
class Blog extends Component<Iprops, any> {
  public componentDidMount(): void {
    this.props.fetchPosts();
  }
  public render(): React.ReactNode {
    const { posts, error } = this.props;
    console.log(posts);
    return (
      <React.Fragment>
        <NavBar background={"#283149"} />
        {!error ? (
          <main id="main-posts" className="wrapper">
            <h1>The Ciro-verse journal</h1>
            <section id="posts-section">
              {posts.length > 0 ? (
                posts.map((post: any) => (
                  <article className="post" key={post.id}>
                    <img
                      src={
                        post.attributes.featured_img ||
                        "https://via.placeholder.com/300x200"
                      }
                      alt=""
                    />
                    <div>
                      <h2>{post.attributes.title}</h2>
                      <div>
                        <Link
                          className="category-link"
                          to={`blog/${post.attributes.category_slug}`}
                        >
                          {post.attributes.category}
                        </Link>
                        <span>
                          {" "}
                          /{" "}
                          {new Date(
                            post.meta.first_published_at
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </span>
                      </div>
                      {Parser(post.attributes.excerpt)}
                      <Link
                        to={`/post/${post.attributes.slug}`}
                        className="post-link"
                      >
                        Keep reading
                      </Link>
                    </div>
                  </article>
                ))
              ) : (
                <>
                  <PostItemLoader className="post" />
                  <PostItemLoader className="post" />
                </>
              )}
            </section>
          </main>
        ) : (
          <Error error={error} />
        )}
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any): object => ({
  error: state.errorMessage,
  posts: state.posts.items.filter((post: any) =>
    ownProps.match.params.category
      ? post.attributes.category_slug === ownProps.match.params.category
      : true
  ),
});
export default connect(mapStateToProps, { fetchPosts })(Blog);
