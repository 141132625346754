// Import modules
import FooterContact from "../FooterContact";
import * as React from "react";
import { PureComponent } from "react";
// import Modal from "./Modal";
// State interface
interface Istate {
  isModalVisible: boolean;
}
// Footer Component
class Footer extends PureComponent<{}, Istate> {
  // state = { isModalVisible: false };
  /* public handleModal = (): void => {
    this.setState({ isModalVisible: this.state.isModalVisible ? false : true });
  } */
  public render(): React.ReactNode {
    return (
      <React.Fragment>
        {/* this.state.isModalVisible && <Modal toggle={this.handleModal} /> */}
        <footer>
          <div id="contact" className="wrapper">
            <h2>Contact me!</h2>
            <FooterContact />
          </div>
          <p>Ciro Villafraz, 2018</p>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
