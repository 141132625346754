import axios from "axios";
import { Dispatch } from "redux";
import { FETCH_POSTS, HANDLE_ERRORS } from "./types";
export const fetchPosts =
  () =>
  (dispatch: Dispatch): void => {
    axios
      .get(
        "https://site-api.datocms.com/items?filter[type]=post&order_by=_firstPublishedAt_DESC",
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_DATOCMS_KEY,
            Accept: "application/json",
            "X-api-version": "3",
          },
        }
      )
      .then((res) => dispatch({ payload: res.data.data, type: FETCH_POSTS }))
      .catch((err) => dispatch({ type: HANDLE_ERRORS, error: err }));
  };
