import React from "react";
import ContentLoader from "react-content-loader";

const PostItemLoader = (props: any) => (
  <ContentLoader
    viewBox="0 0 100 100"
    foregroundColor="#404b69"
    backgroundColor="#283149"
    preserveAspectRatio="xMinYMin meet"
    className="portfolio-item__placeholder"
    {...props}
  >
    <rect width="100%" height="50" />
    <rect x="20%" y="56" rx="0" ry="0" width="60%" height="8" />
    <rect x="4%" y="74" rx="0" ry="0" width="92%" height="8" />
    <rect x="4%" y="84" rx="0" ry="0" width="92%" height="8" />
  </ContentLoader>
);

export default PostItemLoader;
