import * as React from "react";
import { Helmet } from "react-helmet";

interface PostHelmetProps {
  featured_img: string;
  title: string;
  excerpt: string;
}

const PostHelmet = (props: PostHelmetProps): React.ReactElement<any> => {
  let { title, excerpt, featured_img } = props;
  excerpt = excerpt.replace(/<([^>]+)>|\\n/gi, "");
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={excerpt} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@cvillafraz" />
      <meta name="twitter:creator" content="@cvillafraz" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={featured_img} />
      <meta name="twitter:description" content={excerpt} />
      <meta name="twitter:url" content={document.URL} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={featured_img} />
      <meta property="og:description" content={excerpt} />
      <meta property="og:url" content={document.URL} />
    </Helmet>
  );
};

export default PostHelmet;
