import * as React from "react";
import { Helmet } from "react-helmet";
import banner from "../../resources/img/Banner.png";

const HomeHelmet = (): React.ReactElement<any> => {
  const description =
    "NLP Data Scientist who builds applications with LLMs";
  const title = "Ciro Villafraz | Data Scientist";
  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@cvillafraz" />
      <meta name="twitter:creator" content="@cvillafraz" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={banner} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={document.URL} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={banner} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={document.URL} />
    </Helmet>
  );
};

export default HomeHelmet;
