import * as React from "react";
import { Provider } from "react-redux";
import store from "./main/store";
import Routes from "./main/routes";
// App component
function App(): React.ReactElement<{}> {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}
export default App;
