// import modules
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PostHelmet from "./PostHelmet";
import { fetchPosts } from "../../library/common/actions/postAction";
// import DisqusThread from "../Blog/DisqusThread.tsx";
import Error from "../../library/common/components/Error";
import Footer from "../../library/common/components/Footer";
import NavBar from "../../library/common/components/NavBar";
import PostContentLoader from "./loader";

const Parser = require("html-react-parser");
// Post component
class Post extends Component<any, any> {
  public componentDidMount(): void {
    if (this.props.posts.length < 1) {
      this.props.fetchPosts();
    }
  }
  public render(): React.ReactNode {
    // Get the post
    const entry =
      this.props.posts.length === 1 &&
      this.props.posts.reduce((a: any, b: any) => a);
    return (
      <React.Fragment>
        {entry && (
          <PostHelmet
            title={entry.attributes.title}
            featured_img={entry.attributes.featured_img}
            excerpt={entry.attributes.excerpt}
          />
        )}
        <NavBar background="#283149" />
        {!this.props.error ? (
          <main id="main-post" className="wrapper">
            {entry ? (
              <>
                <article className="single-post">
                  <header>
                    <h1>{entry.attributes.title}</h1>
                    <div>
                      <Link
                        className="category-link"
                        to={`/blog/${entry.attributes.category_slug}`}
                      >
                        {entry.attributes.category}
                      </Link>
                      <span>
                        {" "}
                        /{" "}
                        {`Published ${new Date(
                          entry.meta.first_published_at
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}`}
                      </span>
                    </div>
                  </header>

                  <img
                    src={
                      entry.attributes.featured_img ||
                      "https://via.placeholder.com/300x150"
                    }
                    alt=""
                  />
                  {Parser(entry.attributes.content)}
                </article>
                <section className="share-post">
                  <h2>Share this post!</h2>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${document.URL}`}
                    className="share-icon"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={["fab", "facebook"]} />
                  </a>
                  <a
                    href={`https://twitter.com/home?status=${document.URL}`}
                    className="share-icon"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={["fab", "twitter"]} />
                  </a>
                  <a
                    href={
                      "https://www.linkedin.com/shareArticle?mini=true&url=" +
                      document.URL +
                      "&title=" +
                      (entry ? entry.attributes.title : "") +
                      "&summary=&source="
                    }
                    className="share-icon"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={["fab", "linkedin"]} />
                  </a>
                </section>
              </>
            ) : (
              <PostContentLoader />
            )}
          </main>
        ) : (
          <Error error={this.props.error} />
        )}
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => ({
  error: state.errorMessage,
  posts: state.posts.items.filter(
    (post: any) => post.attributes.slug === ownProps.match.params.post_slug
  ),
});

export default connect(mapStateToProps, { fetchPosts })(Post);
